import React, { useRef, useState, useEffect, FunctionComponent } from 'react'
import { IconClose } from './icons'

interface Props {
  openState: boolean
  closeCallback: (
    event: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
  ) => void
}

const SidePanel: FunctionComponent<Props> = ({ openState, closeCallback, children }) => {
  const [open, setOpen] = useState(openState)
  const panel = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (openState !== open) {
      setOpen(openState)
      if (openState && panel.current) {
        panel.current.focus()
      }
    }
    if (open) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
  })

  const onKeyPressed = (event: React.KeyboardEvent<HTMLElement>) => {
    const { keyCode } = event
    if (keyCode === 27) {
      closeCallback(event)
    }
  }

  // TODO: Möglicherweise sinnvoller zu lösen mit einem transparenten Hintergrund über die gesamte App hinterm Panel.
  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClick)

  //   return () => {
  //     document.removeEventListener('mousedown', handleClick)
  //   }
  // }, [])

  // const handleClick = (event: MouseEvent) => {
  //   if (panel && panel.current && panel.current.contains((event.target as HTMLElement))) {
  //     return
  //   }
  //   console.log(event.target)
  //   closeCallback(event as any)
  // }

  return (
    <>
      <div></div>
      <aside
        className={`w=2/3 lg:w=1/2 px=4 py=4 c=text elevated modal ${open ? 'in' : 'out'}`}
        onKeyDown={onKeyPressed}
        tabIndex={0}
        ref={panel}
      >
        <button type="button" className="close c=text" onClick={event => closeCallback(event)}>
          <IconClose />
        </button>
        {children}
        <style jsx>{`
          .modal {
            position: fixed;
            height: 100%;
            overflow-y: scroll;
            top: 0;
            left: 100%;
            transition: transform 0.5s ease-in-out;
            z-index: 99;
            transform: translateX(10%);
          }
          .out > * {
            display: none;
          }
          .in {
            transform: translateX(-100%);
          }
          .close {
            position: absolute;
            left: 10px;
            top: 10px;
          }
        `}</style>
      </aside>
    </>
  )
}

export default SidePanel

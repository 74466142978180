import { ErrorMessage, Field } from 'formik'
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder: string
  type: string
  name: string
  notFormik?: boolean
}

const FloatingLabel: React.FunctionComponent<Props> = props => {
  const { placeholder, name, className, notFormik } = props
  const newProps = { ...props }
  delete newProps.notFormik

  return (
    <>
      <div className={`${className} floating-label my=2 pt=3 c=sec`}>
        {!notFormik && <Field {...newProps} className={`mb=1 px=2 py=1 floating-label__input`} placeholder=" " />}
        {notFormik && (
          <>
            <input {...newProps} className={`mb=1 px=2 py=1 floating-label__input`} placeholder=" " />
          </>
        )}
        <label className={`w=full c=sec left floating-label__label text-s pt=1 pl=1`}>{placeholder}</label>
        <style jsx>{`
          .floating-label__label {
            left: 0 !important;
            text-shadow: none !important;
            display: flex;
            background-color: transparent;
            height: 100%;
            top: 0 !important;
            padding-left: 0.72917rem !important;
            padding-right: 0.72917rem;
            padding-top: 0.35rem;
            padding-bottom: 0.35rem;
            pointer-events: none;
          }
          .floating-label:focus-within {
            box-shadow: 0px 0px 4px 2px rgba(102, 109, 105, 0.3);
          }
        `}</style>
      </div>
      {!notFormik && <ErrorMessage name={name} className="c=err px=2 py=1" component="div" />}
    </>
  )
}

export default FloatingLabel

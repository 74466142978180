import { useFetch, createUrl, ResponseWithId, ResponseWithSuccess } from './fetch'

export type FileMeta = {
  id?: string
  name: string
  description: string
  size: number
  extension: string
  createdAt: Date
  updatedAt: Date
}

export type FileMetaUpdate = {
  id?: string
  name?: string
  description?: string
  size?: number
  extension?: string
  public?: boolean
}

const relativUrl = '/v1/files'

export async function uploadFile(token: string, fileMeta: FileMetaUpdate & { file: File }): Promise<ResponseWithId> {
  const url = createUrl(`${relativUrl}`)
  const formData = new FormData()
  formData.set('file', fileMeta.file)
  if (fileMeta.name) {
    formData.set('name', fileMeta.name)
  }

  if (fileMeta.description) {
    formData.set('description', fileMeta.description)
  }

  return useFetch({ token, url, method: 'POST', body: formData, withoutJson: true })
}

export async function updateFile(token: string, fileMeta: FileMeta, fileId: string): Promise<ResponseWithId> {
  const url = createUrl(`${relativUrl}/${fileId}`)
  return useFetch({ token, url, method: 'PUT', body: fileMeta })
}

export async function getFiles(token: string): Promise<FileMeta[]> {
  const url = createUrl(`${relativUrl}`)
  return useFetch({ token, url, method: 'GET' })
}

export async function deleteFile(token: string, fileId: string): Promise<ResponseWithSuccess> {
  const url = createUrl(`${relativUrl}/${fileId}`)
  return useFetch({ token, url, method: 'DELETE' })
}
